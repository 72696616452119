*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing:	border-box;
  -webkit-box-sizing:border-box;
}

#root{
  overflow-y: hidden;
}

html{
  font-size: 62.5%;
  scroll-behavior: smooth;
  
}

section[id]{
  scroll-margin-top:10vh;
}

div[id]{
  scroll-margin-top:7vh;
}

h2[id]{
  scroll-margin-top:10vh;
}

img {
  max-width: 100%;
  display: block;
}

body{
  font-family: "Inter", "sans-serif";
}

/* Estilos del tab */
.block-tabs {
  display: flex;
  gap: 1rem;
  border-bottom: .1rem solid #00ADEF;
  
}

.block-tabs > button{
  font-size: 20px;

}

@media (max-width: 840px) {
  .block-tabs > button{
  font-size: 16px;
  }
}

@media (max-width: 620px) {
  .block-tabs{
    gap: .2rem;
  }
  .block-tabs > button{
  font-size: 14px;
  }
} 

@media (max-width: 450px) {
  .block-tabs > button{
  font-size: 12px;
  }
}

@media (max-width: 360px) {
  .block-tabs > button{
  font-size: 10px;
  }
} 


/* #00ADEF */
.tabs {
  padding-bottom: .2rem;
  width: 50%;
  background: rgba(128, 128, 128, 0.000);
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.active-tabs  {
  font-weight: 700;
  font-size: 1.4rem;
  background-color:#00ADEF ;
  padding: 2rem 0rem;
  color: #fff;
  transition: background-color 1s, color .3s ease;
  
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background: #00ADEF;

}


button {
  border: none;
  color: #032A45;
  font-weight: 700;
  font-family: "Inter", "sans-serif"; 
}

.content-tabs {
  flex-grow : 1;
}
.content {
  width: 100%;
  height: 100%;
  display: none;
  
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
  border-radius: .8rem;
  margin: 1rem 0;
}

/* scrollspy */

.active-scroll-spy {
  color: #FFAD62;
  /* background-color:#FF6109 ; */
}

@media (max-width: 576px) {
  .tabs{
    /* font-size:1.2rem ; */
    font-weight: 400;
  }
  
  .active-tabs{
    /* font-size: 1.4rem; */
    font-weight: 700;
  }

  
}











